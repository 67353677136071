import React, {Component} from 'react';
import {connect} from 'react-redux';

import axios from '../../utils/axios';

import {Scrollbars} from 'react-custom-scrollbars';

import VK from '../../helpers/VK';


import Modal from "../Modal/Modal";

import './Life.scss';
import * as sysActions from "../../store/sys/actions";
import * as userActions from "../../store/user/actions";


class Life extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalOpenFriends: false,

            items: [
                {
                    price: 50,
                    count: 1
                },
                {
                    price: 100,
                    count: 2
                },
                {
                    price: 150,
                    count: 3
                },
                {
                    price: 200,
                    count: 4
                },
                {
                    price: 250,
                    count: 5
                }
            ],

            blockBuy: false,

            friends: [],

            timeLife: "00 : 00",
        };

        this.idTimer = null;
    }

    componentDidMount() {
        let tmpFriends = this.props.vk.friends;

        if(this.props.vk.friends.length < 10) {
            tmpFriends = [...this.props.vk.friends];
            let plus = 10 - this.props.vk.friends.length;

            for(let i = 0; i < plus; i++) {
                tmpFriends.push({
                    first_name: "Прегласить",
                    last_name: "Друга"
                });
            }
        }

        this.setState({
            friends: tmpFriends
        });


        if(this.props.user.life >= 5) {
            clearInterval(this.idTimer);

            return;
        }

        let minutes = 20;

        // сколько времени прошло после последнего обновления жизний в секундах
        let passedTimeUpdateLife = this.props.user["passed_time_update_life"];

        if(this.props.user.lastTimeUpdateLife) {
            passedTimeUpdateLife = (Date.now() - this.props.user.lastTimeUpdateLife) / 1000;
        }

        if(!this.props.user.lastTimeUpdateLife) {
            let up = Date.now() - (passedTimeUpdateLife * 1000);

            if((Date.now() + minutes * 60 * 1000) - (Date.now() + passedTimeUpdateLife * 1000) <= 0) {
                up = Date.now();
            }

            this.props.userUpdate({
                lastTimeUpdateLife: up
            });
        }

        let sec = ((Date.now() + minutes * 60 * 1000) - (Date.now() + passedTimeUpdateLife * 1000)) / 1000;

        let timer = () => {
            this.idTimer = setInterval(() => {
                if(sec <= 0) {
                    clearInterval(this.idTimer);

                    axios.get("/api/users.updateLife").then(res => {
                        let life = +this.props.user.life + 1;
                        this.props.userUpdate({
                            life,
                            lastTimeUpdateLife: Date.now()
                        });

                        if(life >= 5) {
                            clearInterval(this.idTimer);
                            this.props.userUpdate({
                                lastTimeUpdateLife: null
                            });

                            this.setState({
                                timeLife: "00 : 00"
                            });
                            return;
                        }

                        sec = minutes * 60;
                        timer();
                    }).catch(error => {
                        console.log(error);
                    });

                    return;
                }

                let m = Math.floor(sec / 60);
                let s = Math.floor(sec % 60);

                if(m < 10) {
                    m = '0' + m;
                }
                if(s < 10) {
                    s = '0' + s;
                }
                this.setState({
                    timeLife: m + " : " + s
                });

                sec -= 1;
            }, 1000);
        };

        timer();
    }

    componentWillUnmount() {
        clearInterval(this.idTimer);
    }

    clickBuyGold() {
        this.props.setPopout(null);

        this.props.setActiveModal({
            gold: true
        });
    }

    onClickAsk(id) {
        VK.addCallback('onRequestSuccess', function(d) {
            axios.get("/api/users.setMessage", {
                params: {
                    id_message: 1,
                    id_to: id
                }
            }).then(res => {
                console.log(res);
            }).catch(error => {
                console.log(error);
            });
        });
        VK.addCallback('onRequestCancel', function(d) {
            console.log(d);
        });
        VK.addCallback('onRequestFail', function(d) {
            console.log(d);
        });

        VK.callMethod("showRequestBox", id, "Не могу продолжить игру, нужна жизнь, помоги мне", "ffdfgd");
    }

    clickBuy(i) {
        this.setState({
            blockBuy: true
        });

        if(+this.props.user.gold < this.state.items[i]['price']) {
            this.props.setPopout(
                <Modal style={{width: 400, height: 200}}
                       title="Ошибка"
                       onCloseModal={() => this.props.setPopout(null)}
                >
                    <div className="error_message">
                        У вас недостаточно монет для покупки
                        <div className="button_global_wrap">
                            <div className="button button_two" onClick={(e) => (this.clickBuyGold(true))}>
                                Приобрести монет
                            </div>
                        </div>
                    </div>
                </Modal>
            );

            this.setState({
                blockBuy: false
            });

            return;
        }

        if(+this.props.user.life + this.state.items[i]['count'] > 5) {
            this.setState({
                blockBuy: false
            });

            return;
        }

        axios.get("/api/users.buyLife", {
            params: {
                count: this.state.items[i]['count']
            }
        }).then(res => {
            this.setState({
                blockBuy: false
            });

            if(res.data.error) {
                this.props.setPopout(
                    <Modal style={{width: 400, height: 200}}
                           title="Ошибка"
                           onCloseModal={() => this.props.setPopout(null)}
                    >
                        <div className="error_message">
                            {res.data.error.message}
                        </div>
                    </Modal>
                );

                return;
            }

            this.props.userUpdate({
                gold: +this.props.user.gold - this.state.items[i]['price'],
                life: +this.props.user.life + this.state.items[i]['count']
            });
        }).catch(error => {
            console.log(error);
        });
    }

    render() {
        let userLife = [];
        for(let i = 0; i < +this.props.user.life; i++) {
            userLife.push(1);
        }

        return (
            <div>
                {this.props.sys.modals.life? (
                    <Modal
                        title="Покупка Жизней"
                        onCloseModal={() => this.props.setActiveModal({life: false})}
                        style={{width: 560, height: 340}}
                    >
                        <div className="buy_life_wrap">
                            <div className="chibi"/>

                            <div className="ask_friends" onClick={() => this.setState({modalOpenFriends: true})}>
                                Спросить у друзей
                            </div>

                            <div className="block_items">
                                {this.state.items.map((item, i) => {
                                    let bBuyClass = "button_buy";

                                    if(item.count > 5 - +this.props.user.life || +this.props.user.gold < item.price) {
                                        bBuyClass = "button_buy not_active";
                                    }

                                    return(
                                        <div key={item.price} className="item">
                                            <div className="count_heart">
                                                {item.count}
                                                <div className="heart">
                                                    <img src="/images/ui/buy_life/heart_1.png" alt=""/>
                                                </div>
                                            </div>
                                            <div className="text_gold">{item.price}</div>
                                            <div className={bBuyClass}
                                                 onClick={(this.state.blockBuy ? null : this.clickBuy.bind(this, i))}>
                                                Купить
                                            </div>
                                            <div className="coin"/>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </Modal>
                ) : null}

                {this.state.modalOpenFriends? (
                    <Modal
                        title="У кого спросить"
                        onCloseModal={() => this.setState({modalOpenFriends: false})}
                        style={{width: 680, height: 432}}
                    >
                        <div className="clear_fix">
                            <div style={{height: "432px"}}>
                                <Scrollbars renderThumbVertical={() => <div className="thumb_v"/>}>
                                    {this.state.friends.map((e, i) => (
                                        <div key={i} className="life_ask_friends fl_l">
                                            <div className="avatar">
                                                {e.photo_50? (
                                                    <img src={e.photo_50} alt="" />
                                                ) : (
                                                    <img src="/images/empty_friend.jpg" alt="" />
                                                )}
                                            </div>
                                            <div className="name">
                                                {e.first_name} <br />
                                                {e.last_name}
                                            </div>
                                            {e.photo_50? (
                                                <div className="button" onClick={this.onClickAsk.bind(this, e.id)}>
                                                    Спросить
                                                </div>
                                            ) : (
                                                <div className="button" style={{fontSize: "15px"}}
                                                     onClick={() => VK.callMethod("showInviteBox")}>
                                                    Прегласить
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </Scrollbars>
                            </div>
                        </div>
                    </Modal>
                ) : null}

                <div className="wrap_life">
                    <div className={this.props.user.life < 5? "timerLife" : "timerLife hide"}>
                        {this.state.timeLife}
                    </div>
                    {userLife.map((e, i) => (
                        <div key={i} style={{left: i * 36 + 14 + "px"}} className="item_life">

                        </div>
                    ))}
                    <div onClick={() => this.props.setActiveModal({life: true})} className="plus"/>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        vk: state.vk,
        sys: state.sys
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setPopout: function (name) {
            dispatch(sysActions.setPopout(name))
        },
        userUpdate: function (name) {
            dispatch(userActions.userUpdate(name))
        },
        setActiveModal: function (name) {
            dispatch(sysActions.setActiveModal(name))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Life);