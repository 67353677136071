import React, {Component} from 'react';

import Modal from '../Modal/Modal';

import Icon from 'react-web-vector-icons';

class Info extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false
        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <React.Fragment>
                {this.state.showModal? (
                    <Modal
                        title="Информация"
                        onCloseModal={() => this.setState({showModal: false})}
                        style={{width: 440, height: 340}}
                    >
                        <div style={{textAlign: "center"}}>
                            <img src="/images/chibi_info.png" alt="" />
                        </div>
                        <br />
                        <div className="information">
                            Если у вас возникнут вопросы касательно игры.<br />
                            Либо вы не можете разобраться в игровом процессе.<br />
                            Вы всегда можете задать вопрос в нашей <a href="https://vk.com/club96283456" target="_blank" rel="noopener noreferrer">группе</a>, или пишите <a href="https://vk.com/id30333918" target="_blank" rel="noopener noreferrer">мне лично</a>.<br />
                            Прежде чем писать ознакомьтесь с правили игры, группы.
                        </div>
                    </Modal>
                ) : null}

                <div className="info" onClick={() => this.setState({showModal: true})}>
                    <Icon
                        name="info"
                        font="FontAwesome"
                        style={{fontSize: 19, color: "inherit"}}
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default Info;