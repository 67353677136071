import {combineReducers} from 'redux';

import userReducer from './user/reducers';
import gameReducer from './game/reducers';
import sysReducer from './sys/reducers';
import vkReducer from './vk/reducers';

const rootReducer = combineReducers({
    user: userReducer,
    game: gameReducer,
    sys: sysReducer,
    vk: vkReducer,
});

export default rootReducer;