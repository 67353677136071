import React, {Component} from 'react';

import PropTypes from 'prop-types';

import './Modal.scss';

class Modal extends Component {
    constructor(props){
        super(props);

        this.state = {
            close: false
        }
    }

    static propTypes = {
        title: PropTypes.string,
        children: PropTypes.node,
        showCloseButton: PropTypes.bool,
        onCloseModal: PropTypes.func
    };

    static defaultProps = {
        title: 'Окно',
        showCloseButton: true
    };

    componentDidMount() {

    }

    closeHandler() {
        this.setState({
            close: true
        });

        if(this.props.onCloseModal) {
            this.props.onCloseModal();
        }
    }

    render() {
        if(this.state.close) {
            return null;
        }

        return(
            <React.Fragment>
                <div className="m_mask">

                </div>
                <div className="m_modal_wrap">
                    <div className="m_global">
                        <div className="top_center"/>
                        <div className="top_left"/>
                        <div className="top_right"/>

                        <div className="left"/>
                        <div className="right"/>

                        <div className="bot_center"/>
                        <div className="bot_left"/>
                        <div className="bot_right"/>

                        {this.props.showCloseButton? (
                            <div onClick={this.closeHandler.bind(this)} className="m_close"/>
                        ) : null}

                        <div className="m_title">
                            {this.props.title ? this.props.title : ""}
                        </div>
                        <div className="m_content" style={this.props.style}>
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Modal;