import React from 'react';

let Hints = (props) => {
    return (
        <div className="hints">
            <div className="hints_all add_letter" onClick={(e) => props.hintAddLetter(e)}>
                <div className="minus_gold">
                    25

                    <div className="coin">
                        <img src="/images/ui/buy_life/coin.png" alt="" />
                    </div>
                </div>

                Добавить букву
            </div>

            <div className="hints_all remove_excess" onClick={(e) => props.hintRemoveExcess(e)}>
                <div className="minus_gold">
                    40

                    <div className="coin">
                        <img src="/images/ui/buy_life/coin.png" alt="" />
                    </div>
                </div>

                Убрать лишние
            </div>

            <div className="hints_all skip" onClick={(e) => props.hintSkipAnime(e)}>
                <div className="minus_gold">
                    100

                    <div className="coin">
                        <img src="/images/ui/buy_life/coin.png" alt="" />
                    </div>
                </div>

                Прорустить аниме
            </div>

            <div className="hints_all ask_friends" onClick={(e) => props.hintAskFriends(e)}>
                <div className="minus_gold">
                    0

                    <div className="coin">
                        <img src="/images/ui/buy_life/coin.png" alt="" />
                    </div>
                </div>

                Спросить у друзей
            </div>
        </div>
    )
};

export default Hints;