import * as types from './types';

let initState = {
    messages: []
};

export default function userReducer(state = initState, action) {
    switch (action.type) {
        case types.USER_UPDATE:
            return Object.assign({}, state, action.payload);
        default:
            return state;
    }
}