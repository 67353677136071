import React, {Component} from 'react';

import cookie from '../../utils/cookies';
import Icon from "react-web-vector-icons";

class Sound extends Component {
    constructor(props) {
        super(props);

        this.state = {
            soundDisabled: cookie.getCookie("sound_disabled") > 0
        };
    }

    componentDidMount() {

    }

    disabledSound() {
        if(this.state.soundDisabled) {
            cookie.setCookie("sound_disabled", 0, {expires: 60 * 60 * 24 * 30});
        } else {
            cookie.setCookie("sound_disabled", 1, {expires: 60 * 60 * 24 * 30});
        }

        this.setState({
            soundDisabled: !this.state.soundDisabled
        });
    }

    render() {
        return (
            <div onClick={this.disabledSound.bind(this)}>
                <div className="sound">
                    <Icon
                        name="volume-up"
                        font="FontAwesome"
                        style={{fontSize: 21, color: "inherit"}}
                    />
                </div>
                {this.state.soundDisabled ? (
                    <div className="sound_disabled">
                        <Icon
                            name="times"
                            font="FontAwesome"
                            style={{fontSize: 26, color: "inherit"}}
                        />
                    </div>
                ) : ''}
            </div>
        );
    }
}

export default Sound;