import * as types from './types';

export function gameLoadLevelsMap(name) {
    return {
        type: types.GAME_LOAD_LEVELS_MAP,
        payload: name
    }
}

export function gameUpdate(name) {
    return {
        type: types.GAME_UPDATE,
        payload: name
    }
}