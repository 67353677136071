import React, {Component} from 'react';
import {connect} from 'react-redux';

import Lockr from 'lockr';

import VK from '../../helpers/VK';

import audio from '../../utils/audio';

import axios from '../../utils/axios';

import Modal from '../Modal/Modal';

import Everyday from './Everyday';

import './Main.scss';
import * as userActions from "../../store/user/actions";
import * as sysActions from "../../store/sys/actions";

import Header from '../Header/Header';
import Friends from './Friends';
import Chibi from '../Chibi/Chibi';
import * as gameActions from "../../store/game/actions";

class Main extends Component {
    constructor(props) {
        super(props);

        this.state = {
            blockClickLevel: false,

            days: [
                {id: 1, gold: 100},
                {id: 2, gold: 150},
                {id: 3, gold: 200},
                {id: 4, gold: 300},
                {id: 5, gold: 400}
            ],

            checkRepost: true,
        };

        // Уровень на который нажал юзер
        this.selectedLevel = 1;
    }

    componentDidMount() {
        // if(!this.props.game.levelsMap.length) {
        //     this.props.history.replace("/");
        // }
    }

    displayError(message) {
        this.props.setPopout(
            <Modal style={{width: 400, height: 200}}
                   title="Ошибка"
                   onCloseModal={() => this.props.setPopout(null)}
            >
                <div className="error_message">
                    {message}
                </div>
            </Modal>
        );
    }

    loadAnswersForLevel() {
        axios.get("/api/game.getAnswersById", {
            params: {
                level: this.selectedLevel
            }
        }).then(res => {
            if(res.data.error) {
                this.displayError(res.data.error.message);
                return;
            }

            this.props.gameUpdate({
                answers: res.data.response.level,
                selectedLevel: this.selectedLevel
            });

            this.props.userUpdate({
                selected_level: this.selectedLevel
            });

            this.props.history.replace("/game");
        }).catch(error => {
            this.displayError("Возникла серверная ошибка, перезапустите игру");
            console.log(error);
        });
    }

    clickBuyLife() {
        this.props.setPopout(null);

        this.props.setActiveModal({
            life: true
        });
    }

    clickGame(level) {
        if(+this.props.user['life'] <= 0) {
            this.props.setPopout(
                <Modal style={{width: 300, height: 200}}
                       title="Закончились жизни"
                       onCloseModal={() => this.props.setPopout(null)}
                >
                    <div className="error_message">
                        У Вас закончились жизней
                        <div className="button_global_wrap">
                            <div className="button button_two" onClick={this.clickBuyLife.bind(this)}>
                                Приобрести жизней
                            </div>
                        </div>
                    </div>
                </Modal>
            );

            return;
        }

        if(this.state.blockClickLevel) {
            return;
        }

        if(level > this.props.user['level']) {
            return;
        }

        this.selectedLevel = +level;

        if(+level !== +this.props.user['selected_level'] && this.props.user['level_answer'] > 0) {
            this.props.setPopout(
                <Modal style={{width: 440, height: 200}}
                       title="Начало уровня"
                       onCloseModal={() => this.props.setPopout(null)}
                >
                    <div className="error_message">
                        <div style={{fontSize: "16px"}}>
                            Ранее Вами был выбрад другой уровень. <br />
                            На уровне <span style={{color: "#dd6e22"}}>{this.props.user['selected_level']} </span>
                            вы уже дали <span style={{color: "#dd6e22"}}>{this.props.user['level_answer']}</span> правильных
                            ответов, ответы на текущем уровне будут обнулины
                        </div>
                        <div onClick={this.clickRepeatedLevel.bind(this)} className="button_repeated">
                            Все равно продолжить
                        </div>
                    </div>
                </Modal>
            );

            return;
        }

        this.loadAnswersForLevel();
    }

    clickRepeatedLevel() {
        this.setState({
            blockClickLevel: true
        });

        axios.get("/api/game.repeatedLevel").then(res => {
            this.props.userUpdate({
                level_answer: 0
            });

            this.props.setPopout(null);

            this.setState({
                blockClickLevel: false
            }, () => {
                this.loadAnswersForLevel();
            });
        }).catch(error => {
            console.log(error);
        });
    }

    arrowMapClick(arrow) {
        this.props.setSpinner(true);

        let idMap = arrow === 'left'? this.props.user['id_map'] - 1 : this.props.user['id_map'] + 1;

        let levelsMap = Lockr.get('levelsMap');

        if(levelsMap['map' + idMap]) {
            this.props.gameLoadLevelsMap(levelsMap['map' + idMap]);

            this.props.userUpdate({
                id_map: idMap
            });

            this.props.setSpinner(null);
            return;
        }

        axios.get("/api/game.getLevelsMap", {
            params: {
                id_map: idMap
            }
        }).then(res => {
            if(res.data.error) {
                this.displayError(res.data.error.message);
                this.props.setSpinner(null);
                return;
            }

            this.props.gameLoadLevelsMap(res.data.response['levels_map']);

            this.props.userUpdate({
                id_map: idMap
            });

            levelsMap['map' + idMap] = res.data.response['levels_map'];

            Lockr.set('levelsMap', levelsMap);

            this.props.setSpinner(null);
        }).catch(error => {
            console.log(error);
            this.props.setSpinner(null);
        });
    }

    closeEverydayModal() {
        if(this.state.checkRepost) {
            VK.api("wall.post", {
                message: "Ежедневный бонус в игре получен!",
                attachments: "photo-96283456_456239982,https://vk.com/app6434261"
            }, (data) => {

            });
        }

        this.props.userUpdate({open_everyday: false});
    }

    checkMark() {
        this.setState({
            checkRepost: !this.state.checkRepost
        });
    }

    render() {
        let arrowLevelPosition = [];

        return (
            <React.Fragment>
                <Header/>

                {this.props.user.access > 8? (
                    <React.Fragment>
                        <div className="admin" onClick={() => this.props.history.replace("/level_map")}>

                        </div>
                    </React.Fragment>
                ) : null}

                {this.props.user['open_everyday']? (
                    <Modal
                        title="Заходи каждый день"
                        showCloseButton={false}
                        style={{width: 660, height: 300}}
                    >
                        <Everyday days={this.state.days}
                                  user={this.props.user}
                                  closeEverydayModal={this.closeEverydayModal.bind(this)}
                                  checkMark={this.checkMark.bind(this)}
                                  checkRepost={this.state.checkRepost} />
                    </Modal>
                ) : null}

                <div className="main">
                    <img src={"/images/maps/map"+this.props.user['id_map']+".jpg"} alt="" />

                    <div className="arrows_map left" onClick={this.arrowMapClick.bind(this, "left")}/>
                    <div className="arrows_map right" onClick={this.arrowMapClick.bind(this, "right")}/>

                    {this.props.game.levelsMap.map((e) => {
                        if(+e.id === +this.props.user['level']) {
                            arrowLevelPosition[0] = e.x;
                            arrowLevelPosition[1] = e.y;
                        }

                        return(
                            <div key={e.id}
                                 style={{left: e.x, top: e.y, cursor: "pointer"}}
                                 className={+e.id < +this.props.user['level']? "item_map level_pass" : "item_map"}
                                 onClick={this.clickGame.bind(this, e.id)}
                                 onMouseEnter={() => audio.soundPlay("hoverLevel", 0.7)}>
                                <div className="text">
                                    {e.id}
                                </div>
                            </div>
                        );
                    })}

                    {arrowLevelPosition[1]? (
                        <React.Fragment>
                            <div style={{
                                backgroundImage: "url('"+this.props.user['photo_50']+"')",
                                top: (+arrowLevelPosition[1] - 34) + "px",
                                left: (+arrowLevelPosition[0] - 30) + "px"
                            }} className="avatar_wrap"/>

                            <div style={{top: (+arrowLevelPosition[1] - 64) + "px", left: (+arrowLevelPosition[0] + 8) + "px"}}
                                 className="level_animation"/>
                        </React.Fragment>
                    ) : null}

                    <Friends/>

                    <Chibi history={this.props.history}/>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        game: state.game,
        sys: state.sys,
        vk: state.vk
    }
}

function mapDispatchToProps(dispatch) {
    return {
        userUpdate: function (name) {
            dispatch(userActions.userUpdate(name))
        },
        setPopout: function (name) {
            dispatch(sysActions.setPopout(name))
        },
        gameUpdate: function (name) {
            dispatch(gameActions.gameUpdate(name))
        },
        setActiveModal: function (name) {
            dispatch(sysActions.setActiveModal(name))
        },
        gameLoadLevelsMap: function (name) {
            dispatch(gameActions.gameLoadLevelsMap(name))
        },
        setSpinner: function (name) {
            dispatch(sysActions.setSpinner(name))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);