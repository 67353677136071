import React, {Component} from 'react';
import {connect} from 'react-redux';

import {Scrollbars} from 'react-custom-scrollbars';

import VK from '../../helpers/VK';

import axios from '../../utils/axios';
import base64_decode from '../../utils/base64_decode';

import Modal from '../Modal/Modal';
import Hints from './Hints';
import Header from '../Header/Header';

import * as userActions from "../../store/user/actions";
import * as sysActions from "../../store/sys/actions";

import './Game.scss';
import * as gameActions from "../../store/game/actions";
import Icon from "react-web-vector-icons";


const symbols = "АБВГДЕЁЖЗИЙКЛМНОПРСТУФХЦЧШЩЪЫЬЭЮЯ";

class Game extends Component {
    constructor(props) {
        super(props);

        this.state = {
            answer: "",
            lettersActive: [],
            letters: [],

            showModalFriends: false,

            friends: []
        };

        this.finishing = false;
        this.refreshOnMain = false;

        this.onUnload = this.onUnload.bind(this);
    }

    componentDidMount() {
        if(!this.props.game.answers[this.props['user']['level_answer']]) {
            this.props.setPopout(
                <Modal style={{width: 400, height: 200}}
                       title="Ошибка загрузки уровня"
                       onCloseModal={() => this.props.setPopout(null)}
                >
                    <div className="error_message">
                        Возникла ошибка при загрузке уровня
                    </div>
                </Modal>
            );

            return;
        }

        this.loadAnswer(this.props['user']['level_answer']);

        let friends = this.props.vk.friends;

        if(this.props.vk.friends.length < 10) {
            friends = [...this.props.vk.friends];
            let plus = 10 - this.props.vk.friends.length;

            for(let i = 0; i < plus; i++) {
                friends.push({
                    first_name: "Прегласить",
                    last_name: "Друга"
                });
            }
        }
        this.setState({
            friends
        });

        window.removeEventListener("unload", this.onUnload);
        window.addEventListener("unload", this.onUnload);
    }

    componentWillUnmount() {
        window.removeEventListener("unload", this.onUnload);
    }

    onUnload() {
        axios.get("/api/users.minusLife").then(() => {

        }).catch(error => {
            console.log(error);
        });
    }

    closeModalError() {
        this.props.setPopout(null);

        if(this.finishing || this.refreshOnMain) {
            this.props.history.replace("/main");
        }
    }

    loadAnswer(levelAnswer) {
        let answer = base64_decode(base64_decode(this.props.game.answers[levelAnswer]['answer'])).toUpperCase();

        let words = answer.split(" ");
        let lettersActive = [];

        let allLetters = "";

        for(let i = 0; i < words.length; i++) {
            lettersActive[i] = [];
            for(let j = 0; j < words[i].length; j++) {
                lettersActive[i][j] = {};
                lettersActive[i][j]['letter'] = null;

                allLetters += words[i][j];
            }
        }

        let maxLetters = allLetters.length;
        let allLettersCount = allLetters.length;

        if(allLettersCount <= 10) {
            maxLetters = allLettersCount + 10;
        }

        if(allLettersCount > 10 && allLettersCount <= 20) {
            maxLetters = allLettersCount + 6;
        }

        if(allLettersCount > 20) {
            maxLetters = allLettersCount + 4;
        }

        if(allLettersCount >= 30) {
            maxLetters = allLettersCount;
        }

        if(maxLetters % 2) {
            maxLetters += 1;
        }

        let letters = [];

        for(let i = 0; i < allLetters.length; i++) {
            letters[i] = {
                display: true,
                letter: allLetters[i]
            };
        }

        for(let i = allLetters.length; i < maxLetters; i++) {
            let rand = Math.round(0 - 0.5 + Math.random() * (symbols.length -  1));
            letters[i] = {
                display: true,
                letter: symbols[rand]
            };
        }

        letters.sort(() => Math.random() - 0.5);

        this.setState({
            answer: answer,
            lettersActive: lettersActive,
            letters: letters
        });
    }


    finishingMessage() {
        this.props.setPopout(
            <Modal style={{width: 400, height: 200}}
                   title="Уровень пройден"
                   onCloseModal={this.closeModalError.bind(this)}
            >
                <div className="error_message">
                    Вам удолось пройти уровень, продолжайте в том же духе
                </div>
            </Modal>
        );

        VK.api("wall.post", {
            message: "Я повысил свой уровень в игре! Мой текущий уровень " + (+this.props.user['level'] + 1),
            attachments: "photo-96283456_456239992,https://vk.com/app6434261"
        }, function (data) {
            console.log(data);
        });

        this.finishing = true;

        let tmpLevel = +this.props.user['level'];
        let tmpSelectedLevel = +this.props.user['level'];
        let tmpIdMap = +this.props.user['id_map'];

        if(+this.props.user['level'] === +this.props.user['selected_level']) {
            tmpLevel = +this.props.user['level'] + 1;
            tmpSelectedLevel = +this.props.user['level'] + 1;
        }

        if(tmpLevel > this.props.game.levelsMap.length) {
            tmpIdMap = tmpIdMap + 1;

            // TODO
            axios.get("/api/game.getLevelsMap", {
                params: {
                    id_map: tmpIdMap
                }
            }).then(res => {
                this.props.gameLoadLevelsMap(res.data.response['levels_map']);
            }).catch(error => {
                console.log(error);
            });
        }

        this.props.userUpdate({
            level: tmpLevel,
            level_answer: 0,
            selected_level: tmpSelectedLevel,
            id_map: tmpIdMap
        });

        axios.post("/api/game.finishingLevel").then(() => {

        }).catch(error => {
            console.log(error);
        });
    }

    onClickItem(ii) {
        let addLetter = false;

        let lettersActive = [...this.state.lettersActive];
        let letters = [...this.state.letters];

        outer:
            for(let i = 0; i < lettersActive.length; i++) {
                for(let y = 0; y < lettersActive[i].length; y++) {
                    if(lettersActive[i][y]['letter'] !== null && ii === lettersActive[i][y]['letter']) {
                        break outer;
                    }
                    if(lettersActive[i][y]['letter'] === null) {
                        lettersActive[i][y]['letter'] = ii;

                        addLetter = true;

                        break outer;
                    }
                }
            }

        if(!addLetter) {
            return;
        }

        letters[ii]['display'] = false;

        let word = '';
        let lengthWordsLetters = 0;
        for(let i = 0; i < lettersActive.length; i++) {
            for(let y = 0; y < lettersActive[i].length; y++) {
                if(lettersActive[i][y]['letter'] !== null) {
                    word += letters[lettersActive[i][y]['letter']]['letter'];
                    lengthWordsLetters++;
                }
            }

            if(i === lettersActive.length - 1) {
                break;
            }

            word += " ";
            lengthWordsLetters++;
        }

        if(lengthWordsLetters >= this.state.answer.length && this.state.answer !== word) {
            let life = this.props.user['life'] - 1;

            axios.get("/api/users.minusLife").then(() => {

            }).catch(error => {
                console.log(error);
            });

            if(life <= 0) {
                this.props.userUpdate({
                    life: 0
                });

                this.props.setPopout(
                    <Modal style={{width: 400, height: 200}}
                           title="Ответ не верный"
                           onCloseModal={this.closeModalError.bind(this)}
                    >
                        <div className="error_message">
                            Вы дали не правильный ответ Вы допустили в названии ошибку, так же у вас закончились жизни
                        </div>
                    </Modal>
                );

                // Выход на главную при закрытии окна ошибки
                this.refreshOnMain = true;

                return;
            }

            this.props.setPopout(
                <Modal style={{width: 400, height: 200}}
                       title="Ответ не верный"
                       onCloseModal={() => this.props.setPopout(null)}
                >
                    <div className="error_message">
                        Вы дали не правильный ответ Вы допустили в названии ошибку
                    </div>
                </Modal>
            );

            this.props.userUpdate({
                life: life
            });
        }

        if(this.state.answer === word) {
            if(+this.props.user['level_answer'] + 1 >= this.props.game.answers.length) {
                this.finishingMessage();

                return;
            }


            let formData = new FormData();
            formData.append('level_answer', "1");

            axios.post("/api/users.set", formData).then(res => {
                console.log(res);
            }).catch(error => {
                console.log(error);
            });

            this.props.userUpdate({
                level_answer: +this.props.user['level_answer'] + 1
            });

            this.loadAnswer(+this.props.user['level_answer'] + 1);

            return;
        }

        this.setState({
            lettersActive: lettersActive,
            letters: letters
        });
    }

    clickActiveItem(i, y) {
        let lettersActive = [...this.state.lettersActive];
        let letters = [...this.state.letters];

        if(lettersActive[i][y]['letter'] === null) {
            return;
        }

        letters[lettersActive[i][y]['letter']]['display'] = true;

        lettersActive[i][y]['letter'] = null;

        this.setState({
            lettersActive: lettersActive,
            letters: letters
        });
    }

    clickBuyGold() {
        this.props.setPopout(null);

        this.props.setActiveModal({
            gold: true
        });
    }

    hintAddLetter() {
        if(+this.props.user.gold < 25) {
            this.props.setPopout(
                <Modal style={{width: 400, height: 200}}
                       title="Не достаточно монет"
                       onCloseModal={() => this.props.setPopout(null)}
                >
                    <div className="error_message">
                        У вас недостаточно монет данного действия
                        <div className="button_global_wrap">
                            <div className="button button_two" onClick={() => (this.clickBuyGold())}>
                                Приобрести монет
                            </div>
                        </div>
                    </div>
                </Modal>
            );

            return;
        }

        this.props.userUpdate({
            gold: +this.props.user['gold'] - 25
        });


        axios.get("/api/game.hintAddLetter").then(() => {

        }).catch(error => {
            console.log(error);
        });

        let lettersActive = [...this.state.lettersActive];
        let letters = [...this.state.letters];

        let wordIndex = 0;
        let letterIndex = 0;
        let currentLetterIndex = 0;

        /**
         * Находим свободную ячейку
         */
        outer:
            for(let i = 0; i < lettersActive.length; i++) {
                currentLetterIndex = 0;

                for(let y = 0; y < lettersActive[i].length; y++) {
                    if(lettersActive[i][y]['letter'] === null) {
                        break outer;
                    }

                    currentLetterIndex++;
                    letterIndex++;
                }

                wordIndex++;
            }

        letterIndex += wordIndex;

        let currentLetter = 0;
        outer1:
            for(let i = 0; i < lettersActive.length; i++) {
                for(let y = 0; y < lettersActive[i].length; y++) {
                    if(lettersActive[i][y]['letter'] !== null
                        && letters[lettersActive[i][y]['letter']]["letter"] === this.state.answer[letterIndex]
                        && letters[lettersActive[i][y]['letter']]["letter"] !== this.state.answer[currentLetter]) {

                        letters[lettersActive[i][y]['letter']]['display'] = true;
                        lettersActive[i][y]['letter'] = null;

                        break outer1;
                    }

                    currentLetter++;
                }

                currentLetter++;
            }

        for(let i = 0; i < letters.length; i++) {
            if(letters[i].letter === this.state.answer[letterIndex] && letters[i]['display'] === true) {
                letters[i]['display'] = false;
                lettersActive[wordIndex][currentLetterIndex]['letter'] = i;

                break;
            }
        }

        let word = '';
        let lengthWordsLetters = 0;
        for(let i = 0; i < lettersActive.length; i++) {
            for(let y = 0; y < lettersActive[i].length; y++) {
                if(lettersActive[i][y]['letter'] !== null) {
                    word += letters[lettersActive[i][y]['letter']]['letter'];
                    lengthWordsLetters++;
                }
            }

            if(i === lettersActive.length - 1) {
                break;
            }

            word += ' ';
            lengthWordsLetters++;
        }

        if(lengthWordsLetters >= this.state.answer.length && this.state.answer !== word) {
            let life = +this.props.user['life'] - 1;


            axios.post("/api/users.minusLife").then(() => {

            }).catch(error => {
                console.log(error);
            });

            if(life <= 0) {
                this.props.userUpdate({
                    life: 0
                });

                this.props.setPopout(
                    <Modal style={{width: 400, height: 200}}
                           title="Ответ не верный"
                           onCloseModal={this.closeModalError.bind(this)}
                    >
                        <div className="error_message">
                            Вы дали не правильный ответ Вы допустили названии ошибку, так же у вас закончились жизни
                        </div>
                    </Modal>
                );

                // Выход на главную при закрытии окна ошибки
                this.refreshOnMain = true;

                return;
            }

            this.props.setPopout(
                <Modal style={{width: 400, height: 200}}
                       title="Ответ не верный"
                       onCloseModal={() => this.props.setPopout(null)}
                >
                    <div className="error_message">
                        Вы дали не правильный ответ Вы допустили в названии ошибку
                    </div>
                </Modal>
            );

            this.props.userUpdate({
                life: life
            });
        }

        if(this.state.answer === word) {
            if(+this.props.user['level_answer'] + 1 >= this.props.game.answers.length) {
                this.finishingMessage();

                return;
            }

            let formData = new FormData();
            formData.append('level_answer', "1");

            axios.post("/api/users.set", formData).then(() => {

            }).catch(error => {
                console.log(error);
            });

            this.loadAnswer(+this.props.user['level_answer'] + 1);

            this.props.userUpdate({
                level_answer: +this.props.user['level_answer'] + 1
            });

            return;
        }

        this.setState({
            lettersActive: lettersActive,
            letters: letters
        });
    }

    hintRemoveExcess() {
        if(+this.props.user.gold < 40) {
            this.props.setPopout(
                <Modal style={{width: 400, height: 200}}
                       title="Не достаточно монет"
                       onCloseModal={() => this.props.setPopout(null)}
                >
                    <div className="error_message">
                        У вас недостаточно монет данного действия
                        <div className="button_global_wrap">
                            <div className="button button_two" onClick={() => (this.clickBuyGold())}>
                                Приобрести монет
                            </div>
                        </div>
                    </div>
                </Modal>
            );

            return;
        }

        this.props.userUpdate({
            gold: +this.props.user['gold'] - 40
        });

        axios.get("/api/game.hintRemoveExcess").then(() => {

        }).catch(error => {
            console.log(error);
        });

        let letters = [...this.state.letters];

        let is_display = false;
        for(let i = 0; i < letters.length; i++) {
            is_display = false;
            if(this.state.answer.indexOf(letters[i].letter) !== -1) {
                if(letters[i]['display'] === false) {
                    continue;
                }
                letters[i]['display'] = true;

                is_display = true;
            }

            if(!is_display) {
                letters[i]['display'] = false;
            }
        }

        this.setState({
            letters: letters
        });
    }

    hintSkipAnime() {
        if(+this.props.user.gold < 100) {
            this.props.setPopout(
                <Modal style={{width: 400, height: 200}}
                       title="Не достаточно монет"
                       onCloseModal={() => this.props.setPopout(null)}
                >
                    <div className="error_message">
                        У вас недостаточно монет данного действия
                        <div className="button_global_wrap">
                            <div className="button button_two" onClick={() => (this.clickBuyGold())}>
                                Приобрести монет
                            </div>
                        </div>
                    </div>
                </Modal>
            );

            return;
        }

        let levelAnswer = +this.props.user['level_answer'] + 1;

        if(+this.props.user['level_answer'] + 1 >= this.props.game.answers.length) {
            levelAnswer = +this.props.user['level_answer'];
        }

        this.props.userUpdate({
            gold: +this.props.user['gold'] - 100,
            level_answer: levelAnswer
        });

        if(+this.props.user['level_answer'] + 1 >= this.props.game.answers.length) {
            this.finishingMessage();

            return;
        }

        axios.get("/api/game.hintSkip").then(() => {

        }).catch(error => {
            console.log(error);
        });

        this.loadAnswer(+this.props.user['level_answer'] + 1);
    }

    hintAskFriends() {
        this.setState({
            showModalFriends: true
        });
    }

    askFriendsClickAsk(id) {
        let img = "/images/answers/map" +
            this.props.user['id_map'] + "/" +
            this.props.game.selectedLevel + "/" +
            this.props.game.answers[this.props.user["level_answer"]]['img'] + ".jpg";

        VK.addCallback('onRequestSuccess', () => {
            axios.get("/api/users.setMessage", {
                params: {
                    id_message: 2,
                    id_to: id,
                    img: img,
                    level: this.props.user['level'],
                    level_answer: this.props.user["level_answer"]
                }
            }).then(() => {

            }).catch(error => {
                console.log(error);
            });
        });
        VK.addCallback('onRequestCancel', function(d) {
            console.log(d);
        });
        VK.addCallback('onRequestFail', function(d) {
            console.log(d);
        });

        VK.callMethod("showRequestBox", id, "Мне нужна помощь в разгадке аниме", "ffdfgd");
    }

    addFriendsForApp() {
        VK.callMethod("showInviteBox");
    }

    onClickForImage() {
        console.log(55);
        this.props.setPopout(
            <Modal style={{width: 530, height: 470}}
                   title="Персонаж"
                   onCloseModal={() => this.props.setPopout(null)}
            >
                <div className="error_message">
                    <img width={550}
                         src={"/images/answers/map" +
                         this.props.user['id_map'] + "/" +
                         this.props.game.selectedLevel + "/" +
                         this.props.game.answers[this.props.user["level_answer"]]['img'] + ".jpg"}
                         alt="" onDragStart={(e) => e.preventDefault()}/>
                </div>
            </Modal>
        );
    }

    leaveTheGame() {
        axios.get("/api/users.minusLife").then(() => {
            this.props.userUpdate({
                life: this.props.user.life - 1
            });

            this.props.setPopout(null);

            this.props.history.replace("/main");
        }).catch(error => {
            console.log(error);
        });
    }

    clickLeaveGame() {
        this.props.setPopout(
            <Modal style={{width: 400, height: 200}}
                   title="Покинуть уровень"
                   onCloseModal={() => this.props.setPopout(null)}
            >
                <div className="error_message">
                    Вы уверены что хотите покинуть уровень? <br />
                    Вы потеряете одну жизнь
                    <div className="button_global_wrap">
                        <div className="button button_two" onClick={() => this.props.setPopout(null)}>
                            Отмена
                        </div>
                        <div className="button button_one" onClick={this.leaveTheGame.bind(this)}>
                            Покинуть уровень
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }

    render() {
        let tmpLettersOne = this.state.letters;
        let tmpLettersTwo = [];

        if(this.state.letters.length > 19) {
            tmpLettersOne = this.state.letters.slice(0, this.state.letters.length / 2);
            tmpLettersTwo = this.state.letters.slice(this.state.letters.length / 2);
        }

        return (
            <div>
                <Header />

                {this.state.showModalFriends? (
                    <Modal
                        title="У кого спросить"
                        onCloseModal={() => this.setState({showModalFriends: false})}
                        style={{width: 680, height: 432}}
                    >
                        <div className="clear_fix">
                            <div style={{height: "432px"}}>
                                <Scrollbars renderThumbVertical={() => <div className="thumb_v"/>}>
                                    {this.state.friends.map((e, i) => (
                                        <div key={i} className="life_ask_friends fl_l">
                                            <div className="avatar">
                                                {e.photo_50? (
                                                    <img src={e.photo_50} alt="" />
                                                ) : (
                                                    <img src="/images/empty_friend.jpg" alt="" />
                                                )}
                                            </div>
                                            <div className="name">
                                                {e.first_name} <br />
                                                {e.last_name}
                                            </div>
                                            {e.photo_50? (
                                                <div className="button" onClick={this.askFriendsClickAsk.bind(this, e.id)}>
                                                    Спросить
                                                </div>
                                            ) : (
                                                <div className="button" style={{fontSize: "15px"}}
                                                     onClick={this.addFriendsForApp.bind(this)}>
                                                    Прегласить
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </Scrollbars>
                            </div>
                        </div>
                    </Modal>
                ) : null}

                <div id="field_game">
                    <div className="leave_game" onClick={this.clickLeaveGame.bind(this)}>
                        <Icon
                            name="times"
                            font="FontAwesome"
                            style={{fontSize: 18, color: "inherit"}}
                        />
                    </div>

                    <Hints hintSkipAnime={this.hintSkipAnime.bind(this)}
                           hintAddLetter={this.hintAddLetter.bind(this)}
                           hintRemoveExcess={this.hintRemoveExcess.bind(this)}
                           hintAskFriends={this.hintAskFriends.bind(this)}
                           user={this.props.user}/>

                    <div className="img_block">
                        <div className="img_wrap" onClick={this.onClickForImage.bind(this)}>
                            <img src={"/images/answers/map" +
                            this.props.user['id_map'] + "/" +
                            this.props.game.selectedLevel + "/" +
                            this.props.game.answers[this.props.user["level_answer"]]['img'] + ".jpg"} alt=""
                                 onDragStart={(e) => e.preventDefault()}/>
                        </div>
                    </div>

                    <div className="info_right">
                        <div className="level_info">
                            Уровень

                            <div className="number">
                                {this.props.user['selected_level']}
                            </div>
                        </div>

                        <div className="answers_info">
                            Ответы

                            <div className="number">
                                {this.props.user['level_answer']}
                            </div>
                        </div>

                        <div className="letters_info">
                            Букв в названии

                            <div className="number">
                                {this.state.answer.replace(/\s+/g, '').length}
                            </div>
                        </div>
                    </div>

                    <div className="letters_active_block">
                        <div className="letters_active">
                            {this.state.lettersActive.map((e, i) => {
                                return (
                                    <div key={i} style={{margin: "0 10px", display: "inline-block"}}>
                                        {
                                            this.state.lettersActive[i].map((z, y) => (
                                                <div key={y} className="item_wrap">
                                                    <div className={(z.letter === null ? 'item item_null' : 'item')}
                                                         onClick={this.clickActiveItem.bind(this, i, y)}>
                                                        {z.letter !== null ? this.state.letters[z.letter]['letter'] : "-"}
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    <div className="letters_wrap_block">
                        <div className="letters_wrap">
                            <div>
                                {tmpLettersOne.map((e, i) => (
                                    <div key={i} className="item_wrap">
                                        <div className={(!e.display? 'empty_item' : 'item')}
                                             onClick={this.onClickItem.bind(this, i)}>
                                            {e.display ? e.letter : "-"}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div>
                                {
                                    tmpLettersTwo.length ?
                                        (
                                            tmpLettersTwo.map((e, i) => (
                                                <div key={i} className="item_wrap">
                                                    <div className={(!e.display? 'empty_item' : 'item')}
                                                         onClick={this.onClickItem.bind(this, i + this.state.letters.length / 2)}>
                                                        {e.display ? e.letter : "-"}
                                                    </div>
                                                </div>
                                            ))
                                        ) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        game: state.game,
        sys: state.sys,
        vk: state.vk
    }
}

function mapDispatchToProps(dispatch) {
    return {
        userUpdate: function (name) {
            dispatch(userActions.userUpdate(name));
        },
        setPopout: function (name) {
            dispatch(sysActions.setPopout(name))
        },
        setActiveModal: function (name) {
            dispatch(sysActions.setActiveModal(name))
        },
        gameLoadLevelsMap: function (name) {
            dispatch(gameActions.gameLoadLevelsMap(name))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Game);
