import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import {connect} from "react-redux";

import Wrapper from './components/Wrapper'

import PageLoader from "./components/PageLoader";
import Main from "./components/Main/Main";
import Game from "./components/Game/Game";
import LevelsMap from "./components/Admin/LevelsMap";
import AddAnswer from "./components/Admin/AddAnswer";


class App extends Component {
    render() {
        return (
            <Wrapper popout={this.props.sys.popout} spinner={this.props.sys.spinner}>
                <Switch>
                    <Route exact path="/" component={PageLoader}/>
                    <Route path="/main" component={Main}/>
                    <Route path="/game" component={Game}/>

                    <Route path="/level_map" component={LevelsMap}/>
                    <Route path="/add_answer/:idLevel?/:idMap?" component={AddAnswer}/>
                    {/*<Route path="/:pageId?/:pId?/:sId?" component={App}/>*/}
                </Switch>
            </Wrapper>
        );
    }
}

function mapStateToProps(state) {
    return {
        sys: state.sys
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
