import React, {Component} from 'react';
import {connect} from 'react-redux';

import './Header.scss';
import Life from './Life';
import Gold from './Gold';

import Messages from './Messages';
import Info from './Info';
import Sound from './Sound';
import * as userActions from "../../store/user/actions";

class Header extends Component {
    // constructor(props) {
    //     super(props);
    // }

    componentDidMount() {

    }

    render() {
        return (
            <div>
                <div className="user_info_wrap">
                    <div className="wrap_img">
                        <img src={this.props.user['photo_50']} alt="" />
                    </div>
                    <div className="text_level">
                        {this.props.user.level}
                    </div>
                    <div className="text_answers">
                        {this.props.user['level_answer'] + " / 20"}
                    </div>
                </div>

                <Life/>

                <Gold/>

                <Messages/>

                <Info/>

                <Sound/>
                <div className="wrap_settings">

                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        game: state.game,
        user: state.user
    }
}

function mapDispatchToProps(dispatch) {
    return {
        userUpdate: function (name) {
            dispatch(userActions.userUpdate(name))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);