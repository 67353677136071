import React, {Component} from 'react';
import {connect} from 'react-redux';


import {Scrollbars} from 'react-custom-scrollbars';

import axios from '../../utils/axios';

import Modal from '../Modal/Modal';

import VK from '../../helpers/VK';

import * as userActions from "../../store/user/actions";
import Icon from "react-web-vector-icons";

const idsMessages = {
    "1": {
        message: "Мне нужна жизнь, помоги мне",
        buttonText: "Отправить",
        img: <img src="/images/ui/buy_life/heart_1.png" alt="" />
    },
    "2": {
        message: "Помоги мне разгадать аниме",
        buttonText: "Помочь",
        img: <img src="/images/ui/buy_life/heart_1.png" alt="" />
    },
    "3": {
        message: "Ответ",
        //buttonText: "Спасибо",
        img: <img src="/images/ui/buy_life/heart_1.png" alt="" />
    }
};

class Messages extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            showModalAskFriends: false
        };

        this.idMessageArr = null;

        this.askInput = null;
    }

    componentDidMount() {
        if(this.props.user.messages.length) {
            let arrUsersMessages = [];

            for(let i = 0; i < this.props.user.messages.length; i++) {
                for(let j = 0; j < this.props.vk.friends.length; j++) {
                    if(this.props.vk.friends[j]['id'] === +this.props.user.messages[i]["id_from"]) {
                        arrUsersMessages.push({...this.props.vk.friends[j], ...this.props.user.messages[i]})
                    }
                }
            }

            this.props.userUpdate({
                messages: arrUsersMessages
            });
        }

        if(this.props.user['open_messages'] > 0) {
            this.setState({
                showModal: true
            });

            let formData = new FormData();
            formData.append('open_messages', 0);

            axios.post("/api/users.set", formData).then(res => {
                console.log(res);
            }).catch(error => {
                console.log(error);
            });

            this.props.userUpdate({
                open_messages: 0
            });
        }
    }

    askSend() {
        let sendOnServer = () => {
            axios.get("api/sendAskMessage", {
                params: {
                    id_message: this.idMessageArr,
                    answer: this.askInput.value
                }
            }).then(res => {
                console.log(res);
            }).catch(error => {
                console.log(error);
            });

            let arr = [...this.props.user.messages];
            arr.splice(this.idMessageArr, 1);

            this.idMessageArr = null;

            this.props.userUpdate({
                messages: arr
            });

            this.closeModalAskFriends();
        };

        VK.addCallback('onRequestSuccess', function(d) {
            sendOnServer();
        });
        VK.addCallback('onRequestCancel', function(d) {
            sendOnServer();
        });
        VK.addCallback('onRequestFail', function(d) {
            console.log(d);
        });

        VK.callMethod("showRequestBox", this.props.user.messages[this.idMessageArr]["id_from"], "Я помог тебе с ответом", "ffdfgd");
    }

    closeModalAskFriends() {
        this.setState({
            showModalAskFriends: false
        });
    }

    clickButton(id, idInArray) {
        if(!this.props.user.messages[idInArray]) {
            return;
        }

        if(+this.props.user.messages[idInArray]["id_message"] === 2) {
            this.idMessageArr = idInArray;

            this.setState({
                showModalAskFriends: true
            });

            return;
        }

        let sendOnServer = () => {
            axios.get("/api/users.sendLifeMessage", {
                params: {
                    id_message: idInArray
                }
            }).then(res => {
                console.log(res);
            }).catch(error => {
                console.log(error);
            });

            let arr = [...this.props.user.messages];
            arr.splice(idInArray, 1);

            this.props.userUpdate({
                messages: arr
            });
        };

        VK.addCallback('onRequestSuccess', function(d) {
            sendOnServer();
        });
        VK.addCallback('onRequestCancel', function(d) {
            sendOnServer();
        });
        VK.addCallback('onRequestFail', function(d) {
            console.log(d);
        });

        VK.callMethod("showRequestBox", id, "Жизнь отправлина можешь продолжать играть", "ffdfgd");
    }

    removeMessage(idInArray) {
        axios.get("/api/users.deleteMessage", {
            params: {
                id_message: idInArray
            }
        }).then(res => {

        }).catch(error => {
            console.log(error);
        });

        let arr = [...this.props.user.messages];
        arr.splice(idInArray, 1);

        this.props.userUpdate({
            messages: arr
        });
    }

    render() {
        return(
            <div>
                {this.state.showModal? (
                    <Modal
                        title="Сообщения"
                        onCloseModal={() => this.setState({showModal: false})}
                        style={{width: 560, height: 440}}
                    >
                        <div style={{height: "440px"}}>
                            <Scrollbars renderThumbVertical={() => <div className="thumb_v"/>}>
                                {this.props.user.messages.length?
                                    this.props.user.messages.map((e, i) => {
                                        return (
                                            <div key={i} className="m_messages_wrap">
                                                <div className="remove_messages" onClick={this.removeMessage.bind(this, i)}>
                                                    <Icon
                                                        name="times"
                                                        font="FontAwesome"
                                                        style={{fontSize: 24, color: "inherit"}}
                                                    />
                                                </div>

                                                <div className="avatar">
                                                    <img src={e.photo_50} alt="" />
                                                </div>
                                                <div className="text">
                                                    <div className="name">
                                                        {e.first_name + " " + e.last_name}
                                                    </div>
                                                    {idsMessages[e.id_message]['message']? idsMessages[e.id_message]['message'] : null}
                                                    {+e.id_message === 3 ? (<span style={{color: "#357914"}}>{" " + e.answer}</span>) : null}
                                                </div>
                                                <div className="img">
                                                    {idsMessages[e.id_message]['img']? idsMessages[e.id_message]['img'] : null}
                                                </div>
                                                {
                                                    idsMessages[e.id_message]['buttonText']? (
                                                        <div className="button" onClick={this.clickButton.bind(this, e.id, i)}>
                                                            {idsMessages[e.id_message]['buttonText']}
                                                        </div>
                                                    ) : null
                                                }
                                            </div>
                                        )
                                    }) : null}
                            </Scrollbars>
                        </div>
                    </Modal>
                ) : null}

                {this.state.showModalAskFriends? (
                    <Modal
                        title="Помоч другу"
                        onCloseModal={() => this.setState({showModalAskFriends: false})}
                        style={{width: 660, height: 440}}
                    >
                        <div className="ask_friends">
                            <div className="img_block">
                                <div className="img_wrap">
                                    {
                                        this.idMessageArr !== null ? (
                                            <img src={this.props.user.messages[this.idMessageArr]["img"]} alt=""
                                                 onDragStart={(e) => e.preventDefault()}/>
                                        ) : null
                                    }
                                </div>
                            </div>

                            <div>
                                <input type="text" className="input_wrap" placeholder="Название аниме"
                                       ref={(e) => (this.askInput = e)}/>
                            </div>
                            <div>
                                <input type="button" className="input_button" value="Отправить ответ"
                                       onClick={this.askSend.bind(this)}/>
                            </div>
                        </div>
                    </Modal>
                ) : null}

                <div className="messages" onClick={() => this.setState({showModal: true})}>
                    <div className="counter_messages">
                        {this.props.user.messages.length ? this.props.user.messages.length : null}
                    </div>
                    <Icon
                        name="envelope"
                        font="FontAwesome"
                        style={{fontSize: 17, color: "inherit"}}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        vk: state.vk
    }
}

function mapDispatchToProps(dispatch) {
    return {
        userUpdate: function (name) {
            dispatch(userActions.userUpdate(name));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Messages);