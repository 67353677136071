import * as types from './types';

let iniState = {
    levelsMap: [],
    answers: [],
    selectedLevel: 1,
};

export default function gameReducer(state = iniState, action) {
    switch (action.type) {
        case types.GAME_LOAD_LEVELS_MAP:
            return Object.assign({}, state, {levelsMap: action.payload});
        case types.GAME_UPDATE:
            return Object.assign({}, state, action.payload);
        default:
            return state;
    }
}