import React, {Component} from 'react';
import {connect} from 'react-redux';

import './Main.scss';

import VK from '../../helpers/VK';
import axios from "../../utils/axios";
import * as sysActions from "../../store/sys/actions";
import * as userActions from "../../store/user/actions";
import Modal from "../Modal/Modal";
import Icon from "react-web-vector-icons";

class Friends extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            activeFriend: {}
        };
    }

    componentDidMount() {

    }

    displayError(message) {
        this.props.setPopout(
            <Modal style={{width: 400, height: 200}}
                   title="Ошибка"
                   onCloseModal={() => this.props.setPopout(null)}
            >
                <div className="error_message">
                    {message}
                </div>
            </Modal>
        );
    }

    onClickArrowRight() {
        if((this.state.page + 1) * 4 > this.props.vk.friends.length) {
            return;
        }

        this.setState({
            page: this.state.page + 1
        });
    }

    onClickArrowLeft() {
        if(this.state.page - 1 < 0) {
            return;
        }

        this.setState({
            page: this.state.page - 1
        });
    }

    clickOnUser(friend) {
        this.setState({
            activeFriend: this.state.activeFriend.id === friend.id? {} : friend
        });
    }

    sendChibi() {
        let friend = this.state.activeFriend;

        if(this.props.user['gifts_left_chibi'] < 1) {
            this.displayError("На сегодня у вас закончилась возможность дарить чиби");
            return;
        }

        if(friend['id'] === this.props.user['id_vk']) {
            this.displayError("Вы не можете дарить сами себе чиби");
            return;
        }

        this.props.setSpinner(true);

        VK.addCallback('onRequestSuccess', () => {
            let formData = new FormData();
            formData.append('id_to', friend['id']);

            axios.post("/api/users.addChibi", formData).then(res => {
                this.props.setSpinner(null);

                if(res.data.error) {
                    this.displayError(res.data.error.message);
                    return;
                }

                this.props.userUpdate({
                    gifts_left_chibi: this.props.user['gifts_left_chibi'] - 1
                });

                this.props.setPopout(
                    <Modal style={{width: 400, height: 300}}
                           title="Чиби отправлен"
                           onCloseModal={() => this.props.setPopout(null)}
                    >
                        <div className="error_message">
                            <div>
                                <img src={"/images/chibi/" + res.data.response.chibi['image'] + ".png"} alt="" />
                            </div>
                            Вы отправили чиби "{res.data.response.chibi['title']}"
                            <div className="button_global_wrap">
                                <div className="button button_two" onClick={() => this.props.setPopout(null)}>
                                    OK
                                </div>
                            </div>
                        </div>
                    </Modal>
                );
            }).catch(error => {
                this.props.setSpinner(null);
                console.log(error);
            });
        });
        VK.addCallback('onRequestCancel', () => {
            this.props.setSpinner(null);

            this.props.setPopout(
                <Modal style={{width: 400, height: 200}}
                       title="Отмена"
                       onCloseModal={() => this.props.setPopout(null)}
                >
                    <div className="error_message">
                        Вы отменили отправку чиби
                    </div>
                </Modal>
            );
        });
        VK.addCallback('onRequestFail', () => {
            this.props.setSpinner(null);
        });

        VK.callMethod("showRequestBox", friend['id'], "Подарок в виде чиби был отправлен", "ffdfgd");
    }

    toAskChibi() {
        let friend = this.state.activeFriend;

        VK.addCallback('onRequestSuccess', (d) => {

        });
        VK.addCallback('onRequestCancel', () => {

        });
        VK.addCallback('onRequestFail', () => {

        });

        VK.callMethod("showRequestBox", friend['id'], "Мне нужен чиби в мою коллекцию", "ffdfgd");
    }

    render() {
        let startItems = this.state.page * 4;
        let maxItems = this.state.page * 4 + 4;

        let countAllFriends = this.props.vk.friends.length;

        let tmpFriends = [];
        for(let i = startItems; i < maxItems; i++) {
            tmpFriends.push(i);
        }

        let friendsVK = this.props.vk.friends;

        return (
            <div className="wrap_friends">
                <div className="menuFriendWrap">
                    <div className="topBlock">

                    </div>
                    <div className={this.state.activeFriend.id? "menuFriend animationF" : "menuFriend"}>
                        <div className="itemWrap" onClick={this.sendChibi.bind(this)}>
                            <div className="blockItem">
                                <img src="/images/ui/main/send.png" alt="" />
                            </div>
                            <div className="textItem">
                                Отправить чиби
                            </div>
                        </div>

                        <div className="itemWrap" onClick={this.toAskChibi.bind(this)}>
                            <div className="blockItem">
                                <img src="/images/ui/main/toAsk.png" alt="" />
                            </div>
                            <div className="textItem">
                                Попросить чиби
                            </div>
                        </div>


                        <a href={"//vk.com/id" + this.state.activeFriend['id']} className="itemWrap" target="_blank" rel="noreferrer noopener">
                            <div className="blockItem">
                                <img src={this.state.activeFriend['photo_50']} alt="" />
                            </div>
                            <div className="textItem">
                                Посмотреть профиль
                            </div>
                        </a>

                        <div className="userName">
                            {this.state.activeFriend['first_name'] + " " + this.state.activeFriend['last_name']}
                        </div>
                    </div>
                </div>

                <div className="arrows arrow_left" onClick={this.onClickArrowLeft.bind(this)}/>
                <div className="arrows arrow_right" onClick={this.onClickArrowRight.bind(this)}/>

                <div style={{left: 105 + "px", cursor: "pointer"}} className="item_friend"
                     onClick={() => VK.callMethod("showInviteBox")}>
                    <div className="avatar">
                        <img src="/images/plus_friend.jpg" alt="" />
                    </div>
                    <div className="name">
                        Пригласить
                    </div>
                    <div className="level_friend">
                        друга
                    </div>
                </div>

                {tmpFriends.map((e, i) => {
                    if(e + 1 > countAllFriends) {
                        return (
                            <div key={e} style={{left: 85 + (i + 1) * 102 + 20 + "px"}} className="item_friend"
                                 onClick={() => VK.callMethod("showInviteBox")}>
                                <div className="avatar">
                                    <img src="/images/empty_friend.jpg" alt="" />
                                </div>
                                <div className="name">
                                    Нет друга
                                </div>
                                <div className="level_friend">
                                    Позвать
                                </div>
                            </div>
                        )
                    }
                    return (
                        <div key={e} style={{left: 85 + (i + 1) * 102 + 20 + "px"}} className="item_friend">
                            <div className="avatar" onClick={this.clickOnUser.bind(this, friendsVK[e])}>
                                <img src={friendsVK[e]['photo_50']} alt="" />
                            </div>
                            <div className="name">
                                {friendsVK[e]['first_name']}
                            </div>
                            <div className="level_friend">
                                {friendsVK[e]['level']}
                            </div>
                        </div>
                    )
                })}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        vk: state.vk,
        user: state.user
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setSpinner: function (name) {
            dispatch(sysActions.setSpinner(name))
        },
        userUpdate: function (name) {
            dispatch(userActions.userUpdate(name));
        },
        setPopout: function (name) {
            dispatch(sysActions.setPopout(name))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Friends);