import React from 'react';
import ReactDOM from 'react-dom';
import thunk from 'redux-thunk';
import {HashRouter} from 'react-router-dom';

import { composeWithDevTools } from 'redux-devtools-extension';

import {Provider} from 'react-redux';
import {applyMiddleware, createStore} from 'redux';

import rootReducer from './store/reducers';

import './index.scss';

import App from './App';

require('react-web-vector-icons/fonts');


const logger = store => next => action => {
    // console.log('dispatching', action);
    return next(action);
};

const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk, logger)));

ReactDOM.render(
    <Provider store={store}>
        <HashRouter>
            <App/>
        </HashRouter>
    </Provider>,
    document.getElementById('root'));
