let $_GET = window
    .location
    .search
    .replace('?','')
    .split('&')
    .reduce(
        function(p,e){
            let a = e.split('=');
            p[ decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
            return p;
        },
        {}
    );


    // $_GET = {
    //     viewer_id: 30333918,
    //     access_token: "dsfdsfsdsd",
    //     auth_key: "dsfsdfdsfds"
    // };


export default $_GET;