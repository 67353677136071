import * as types from './types';

export function setPopout(name) {
    return {
        type: types.SYS_SET_POPOUT,
        payload: name
    }
}

export function setActiveModal(name) {
    return {
        type: types.SYS_SET_ACTIVE_MODAL,
        payload: name
    }
}

export function setSpinner(name) {
    return {
        type: types.SYS_SET_SPINNER,
        payload: name
    }
}