import React from 'react';

import './Everyday.scss';

let Everyday = (props) => {
    return (
        <div className="everyday">
            {props.days.map((e, i) => {
                let img = "url('/images/ui/main/everyday/d"+e.id+".png')";

                if(e.id > +props.user.day) {
                    img = "url('/images/ui/main/everyday/d"+e.id+"_no.png')";
                }

                return (
                    <div key={e.id} className="item" style={{"background": img}}>
                        <div className="title">день {e.id}</div>
                        <div className="gold">{e.gold}</div>
                    </div>
                );
            })}

            <div onClick={props.closeEverydayModal} className="button_get">Получить</div>
            <div className="tell_friends">
                <div onClick={props.checkMark} className="checkbox">
                    {(props.checkRepost ? <div className="is_active"/> : null)}
                </div>
                Рассказать друзьям
            </div>
        </div>
    );
};

export default Everyday;