import React, {Component} from 'react';
import {connect} from 'react-redux';

import * as userActions from "../../store/user/actions";
import axios from "../../utils/axios";

import './LevelsMap.scss';

class Main extends Component {
    constructor(props) {
        super(props);

        this.state = {
            levelsMap: [],
            idMap: 1
        };

        this.coords = {};

        this.countLevels = 20;
        this.idMap = 1;
    }

    componentDidMount() {
        // TODO
        axios.get("/api/admin.getLevels", {
            params: {
                id_map: this.idMap
            }
        }).then(res => {
            if(res.data.response['levels'].length) {
                this.setState({
                    levelsMap: res.data.response['levels']
                });
            } else {
                // let levelsMap = [];
                // for(let i = 0; i < 20; i++) {
                //     levelsMap.push({
                //         x: i * 38,
                //         y: 100
                //     });
                // }
                //
                // this.setState({
                //     levelsMap
                // });
            }
        }).catch(error => {
            console.log(error);
        });
    }

    onDragStart(e) {
        let c = e.target.getBoundingClientRect();
        this.coords = {
            top: e.pageY - c.top,
            left: e.pageX - c.left
        };
    }

    onDragEnd(id, e) {
        e.preventDefault();

        let levelsMap = [...this.state.levelsMap];
        levelsMap[id].x = e.pageX - this.coords.left;
        levelsMap[id].y = e.pageY - this.coords.top;

        this.setState({
            levelsMap
        });
    }

    changeCountLevels(e) {
        this.countLevels = e.target.value;
    }

    saveLevels() {
        let formData = new FormData();
        // TODO
        formData.append('id_map', this.idMap);
        formData.append('json', JSON.stringify(this.state.levelsMap));

        axios.post("/api/admin.saveLevels", formData).then(res => {

        }).catch(error => {
            console.log(error);
        });
    }

    changeIdMap(e) {
        this.idMap = e.target.value;

        this.setState({
            idMap: this.idMap
        });

        axios.get("/api/admin.getLevels", {
            params: {
                id_map: this.idMap
            }
        }).then(res => {
            if(res.data.error) {
                return;
            }

            if(res.data.response['levels'].length) {
                this.setState({
                    levelsMap: res.data.response['levels']
                });
            } else {
                this.setState({
                    levelsMap: []
                });
            }
        }).catch(error => {
            console.log(error);
        });
    }

    addLevelsInMap() {
        let levelsMap = [...this.state.levelsMap];
        for(let i = 0; i < this.countLevels; i++) {
            let shift = 0;
            let top = 100;
            if(i > 20) {
                shift = 20;
                top = 200;
            }
            levelsMap.push({
                x: (i - shift) * 38,
                y: top
            });
        }

        this.setState({
            levelsMap
        });

        console.log(levelsMap);
    }

    render() {
        return (
            <div className="main">
                <img src={"/images/maps/map"+this.idMap+".jpg"} alt="" />

                {this.state.levelsMap.map((e, i) => {
                    return(
                        <div key={i}
                             style={{left: e.x, top: e.y, cursor: "pointer"}}
                             className="item_map"
                             draggable={true}
                             onDragStart={this.onDragStart.bind(this)}
                             onDragEnd={this.onDragEnd.bind(this, i)}
                             onClick={() => this.props.history.replace("/add_answer/" + e.id + "/" + this.state.idMap)}>
                            <div className="text">
                                {i + 1}
                            </div>
                        </div>
                    );
                })}

                <div className="bottomSave" onClick={this.saveLevels.bind(this)}>
                    Сохранить
                </div>

                <input type="text" className="addLevelsInMap" onChange={this.changeCountLevels.bind(this)}/>
                <div className="bottomAddLevelsInMap" onClick={this.addLevelsInMap.bind(this)}>
                    Добавить
                </div>

                <input type="text" className="selectIdMap" value={this.state.idMap} onChange={this.changeIdMap.bind(this)}/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        game: state.game
    }
}

function mapDispatchToProps(dispatch) {
    return {
        userUpdate: function (name) {
            dispatch(userActions.userUpdate(name))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);