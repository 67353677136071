import React, {Component} from 'react';
import {connect} from 'react-redux';

import $_GET from '../helpers/getParams';

import Lockr from 'lockr';

import * as userActions from '../store/user/actions';
import * as gameActions from '../store/game/actions';

import axios from '../utils/axios';
import * as vkActions from "../store/vk/actions";

// import VK from '../helpers/VK';

/* global VK createjs */
class PageLoader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            percent: 0,
            ready: false
        };
    }

    componentDidMount() {
        let id = Lockr.get('viewer_id');
        // TODO
        // if(258749517 !== +$_GET['viewer_id'] && 30333918 !== +$_GET['viewer_id']) {
        //     return;
        // }

        if(!id || +$_GET['viewer_id'] !== +id) {
            Lockr.flush();

            Lockr.set('viewer_id', $_GET['viewer_id']);
        }

        this.miniLoader();
    }

    bigLoader() {
        let preload = new createjs.LoadQueue(false);

        let lis_1 = preload.on("progress", (event) => {
            this.setState({
                percent: Math.round(preload.progress * 100)
            });
        });

        let lis_2 = preload.on("complete", (e) => {
            preload.off("complete", lis_2);
            preload.off("progress", lis_1);

            this.vkStartGame();
        });

        preload.loadManifest([
            "/images/ui/main/wrap_friend.png",
            "/images/ui/main/user_info.png",
            "/images/ui/main/plus.png",
            "/images/ui/main/life.png",
            "/images/ui/main/level_map_pass.png",
            "/images/ui/main/level_map.png",
            "/images/ui/main/bg_settings.png",
            "/images/ui/main/bg_life.png",
            "/images/ui/main/bg_gold.png",
            "/images/ui/main/bg_friends.png",
            "/images/ui/main/arrow_right.png",
            "/images/ui/main/arrow_level.png",
            "/images/ui/main/arrow_left.png",

            "/images/ui/modal/bot_center.png",
            "/images/ui/modal/bot_left.png",
            "/images/ui/modal/bot_right.png",
            "/images/ui/modal/center.png",
            "/images/ui/modal/close.png",
            "/images/ui/modal/left.png",
            "/images/ui/modal/right.png",
            "/images/ui/modal/title.png",
            "/images/ui/modal/top_center.png",
            "/images/ui/modal/top_left.png",
            "/images/ui/modal/top_right.png",
        ]);

        let sounds = [
            {src: "/sounds/error.mp3", id: "errorText"},
            {src: "/sounds/button-mp3.mp3", id: "hoverLevel"}
        ];

        createjs.Sound.registerSounds(sounds);
    }

    vkStartGame() {
        VK.init(() => {
            VK.api("users.get", {
                fields: "photo_50"
            },(data) => {
                let vkUser = data;

                this.props.vkUserUpdate(vkUser['response'][0]);

                let friends = Lockr.get('friends');

                if(friends && friends['lastUpdate'] > Date.now() - 3*60*1000) {
                    this.props.vkFriendsLoad(friends['users']);

                    this.displayGame(vkUser);

                    return;
                }

                VK.api("friends.getAppUsers", {}, (data) => {
                    if(data.error) {
                        return;
                    }

                    let usersIds = data.response;

                    if(usersIds) {
                        axios.get("/api/users.getFriends", {
                            params: {
                                ids: usersIds
                            }
                        }).then(res => {
                            if(res.data.error) {
                                return;
                            }

                            let ids = res.data.response.users.map((e) => {
                                return e['id_vk'];
                            });

                            VK.api("users.get", {user_ids: ids.join(","), fields: "photo_50"}, (data) => {
                                let users = data.response.map((e, i) => {
                                    data.response[i]['level'] = res.data.response.users[i]['level'];
                                    return data.response[i];
                                });

                                this.props.vkFriendsLoad(users);

                                let friends = {
                                    users: [],
                                    lastUpdate: Date.now()
                                };
                                friends['users'] = users;
                                Lockr.set('friends', friends);

                                this.displayGame(vkUser);
                            });
                        }).catch(error => {
                            console.log(error);
                        });
                    } else {
                        this.displayGame(vkUser);
                    }
                });
            });

            // VK.api("apps.getLeaderboard", {"type": "level", "global": 0, "extended": 1}, (data) => {
            //     console.log(data);
            //
            //     if(data.error) {
            //         return;
            //     }
            //
            //     this.props.vkLeadersFriendsLoad(data.response);
            // });
        }, function() {
            console.log(222);
        }, '5.101');
    }

    displayGame(vkUser) {
        axios.get("/api/sys.firstRequest", {
            params: {

            }
        }).then(res => {
            res.data.response.user['photo_50'] = vkUser['response'][0]['photo_50'];
            res.data.response.user['messages'] = res.data.response.messages;

            this.props.userUpdate(res.data.response['user']);

            this.props.gameLoadLevelsMap(res.data.response['levels_map']);

            let levelsMap = {};
            levelsMap['map' + res.data.response['user']['id_map']] = res.data.response['levels_map'];
            Lockr.set('levelsMap', levelsMap);

            setTimeout(() => {
                this.props.history.replace("/main");
            }, 200);
        }).catch(error => {
            console.log(error);
        });
    }

    miniLoader() {
        let miniPreload = new createjs.LoadQueue(false);

        let lis2 = miniPreload.on("fileload", (e) => {
            miniPreload.off("fileload", lis2);

            this.setState({
                ready: true
            });

            this.bigLoader();
        });

        miniPreload.loadFile("/images/main.jpg");
    }

    render() {
        return (
            <div>
                {this.state.ready ? (
                    <div>
                        <div id="age_restriction">6+</div>
                        <div id="preload_wrap">
                            <div id="line_bot">
                                <div id="percent">{this.state.percent + "%"}</div>
                                <div id="line_top" style={{width: this.state.percent + "%"}} />
                            </div>
                        </div>
                        <img src="/images/main.jpg" alt="" />
                    </div>
                ) : (
                    <div id="word_load">
                        Загрузка... <br/>
                        {/*Идут технические работы*/}
                    </div>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user
    }
}

function mapDispatchToProps(dispatch) {
    return {
        userUpdate: function (name) {
            dispatch(userActions.userUpdate(name))
        },
        gameLoadLevelsMap: function (name) {
            dispatch(gameActions.gameLoadLevelsMap(name))
        },
        vkUserUpdate: function (name) {
            dispatch(vkActions.vkUserUpdate(name))
        },
        vkFriendsLoad: function (name) {
            dispatch(vkActions.vkFriendsLoad(name))
        },
        vkLeadersFriendsLoad: function (name) {
            dispatch(vkActions.vkLeadersFriendsLoad(name))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(PageLoader);