import * as types from './types';

export function vkUserUpdate(name) {
    return {
        type: types.VK_USER_UPDATE,
        payload: name
    }
}

export function vkFriendsLoad(name) {
    return {
        type: types.VK_FRIENDS_LOAD,
        payload: name
    }
}

export function vkLeadersFriendsLoad(name) {
    return {
        type: types.VK_LEADERS_FRIENDS_LOAD,
        payload: name
    }
}