import * as types from './types';

let initState = {
    popout: null,
    modals: {
        life: false,
        gold: false
    },
    spinner: null
};

export default function sysReducer(state = initState, action) {
    switch (action.type) {
        case types.SYS_SET_POPOUT:
            return Object.assign({}, state, {popout: action.payload});
        case types.SYS_SET_ACTIVE_MODAL:
            let modals = Object.assign({}, state['modals'], action.payload);
            return Object.assign({}, state, {modals: modals});
        case types.SYS_SET_SPINNER:
            return Object.assign({}, state, {spinner: action.payload});
        default:
            return state;
    }
}