import * as types from './types';

let initState = {
    user: {},
    friends: [],
    leadersFriends: []
};

export default function vkReducer(state = initState, action) {
    switch (action.type) {
        case types.VK_USER_UPDATE:
            let user = Object.assign({}, state['user'], action.payload);
            return Object.assign({}, state, {user: user});
        case types.VK_FRIENDS_LOAD:
            return Object.assign({}, state, {friends: action.payload});
        case types.VK_LEADERS_FRIENDS_LOAD:
            return Object.assign({}, state, {leadersFriends: action.payload});
        default:
            return state;
    }
}