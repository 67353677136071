import React, {Component} from 'react';
import {connect} from 'react-redux';

class Wrapper extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    componentDidMount() {

    }

    stopClickRight(e) {
        // TODO
        // e.preventDefault();
    }

    render() {
        return (
            <div id="wrapper" onContextMenu={this.stopClickRight.bind(this)}>
                <div id="game">
                    {this.props.children}

                    <div>
                        {this.props.popout}
                    </div>

                    {this.props.spinner? (
                        <React.Fragment>
                            <div className="maskSpinner"/>
                            <div className="spinner">
                                <div className="img" />
                            </div>
                        </React.Fragment>
                    ) : null}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Wrapper);