import React, {Component} from 'react';
import {connect} from 'react-redux';

import {Scrollbars} from 'react-custom-scrollbars';

import axios from '../../utils/axios';

import Modal from '../Modal/Modal';

import * as userActions from "../../store/user/actions";
import Icon from "react-web-vector-icons";

import './Chibi.scss';
import * as sysActions from "../../store/sys/actions";

class Chibi extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            chibi: [],
            // selectedChibi: []
        };
    }

    componentDidMount() {
        // TODO
        // if(!this.props.user['chibi'] || !this.props.user['chibi'].length) {
        //     this.props.history.replace("/");
        //     return;
        // }

        this.loadChibi();
    }

    loadChibi() {
        let chibi = [];
        for(let i = 0; i < 12; i++) {
            chibi.push([]);
        }

        let idBoard = 0;
        for(let i = 0; i < 48; i++) {
            if(chibi[idBoard].length >= 4) {
                idBoard++;
            }

            if(this.props.user['chibi'][i]) {
                this.props.user['chibi'][i]['id_arr'] = i;
                chibi[idBoard].push(this.props.user['chibi'][i]);
            } else {
                chibi[idBoard].push(1);
            }
        }

        this.setState({
            chibi
        });
    }

    displayError(message) {
        this.props.setPopout(
            <Modal style={{width: 400, height: 200}}
                   title="Ошибка"
                   onCloseModal={() => this.props.setPopout(null)}
            >
                <div className="error_message">
                    {message}
                </div>
            </Modal>
        );
    }

    clichChibi(idBoard, id) {
        let chibi = [...this.state.chibi];

        chibi[idBoard][id]['check'] = !chibi[idBoard][id]['check'];

        // let selectedChibi = [...this.state.selectedChibi];
        //
        // if(chibi[idBoard][id]['check']) {
        //     selectedChibi.push(chibi[idBoard][id]['id_arr']);
        // } else {
        //     selectedChibi.push(chibi[idBoard][id]['id_arr']);
        // }

        this.setState({
            chibi,
            // selectedChibi
        });
    }

    getPrice() {
        let price = 0;
        this.state.chibi.forEach((board) => {
            board.forEach((e) => {
                if(e.check) {
                    if(e['improved'] === 1) {
                        price += 100
                    } else {
                        price += 5
                    }
                }
            });
        });

        return price;
    }

    toSell() {
        let idsArr = [];

        let sell =() => {
            this.props.setSpinner(true);

            let formData = new FormData();
            formData.append('ids', idsArr);

            axios.post("/api/users.toSellChibi", formData).then(res => {
                this.props.setSpinner(null);

                if(res.data.error) {
                    this.displayError(res.data.error.message);
                    return;
                }

                this.props.userUpdate({
                    chibi: res.data.response.chibi,
                    gold: this.props.user.gold += res.data.response.price
                });

                this.loadChibi();
            }).catch(error => {
                console.log(error);
            });

            this.props.setPopout(null);
        };

        let cancel =() => {
            this.props.setPopout(null)
        };

        this.state.chibi.forEach((board) => {
            board.forEach((e) => {
                if(e.check) {
                    idsArr.push(e['id_arr']);
                }
            });
        });

        if(!idsArr.length) {
            this.props.setSpinner(null);
            this.displayError("Нужно выбрать хотябы оного чиби, что бы продать, Нажмите на чиби которого хотите продать");
            return;
        }

        this.props.setPopout(
            <Modal style={{width: 400, height: 200}}
                   title="Ошибка"
                   onCloseModal={() => this.props.setPopout(null)}
            >
                <div className="error_message">
                    Вы уверенны, что хотите придать выбранных чиби?
                    <div className="button_global_wrap">
                        <div className="button button_two" onClick={cancel}>
                            Отмена
                        </div>
                        <div className="button button_one" onClick={sell}>
                            Продать
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }

    toImprove() {
        this.props.setSpinner(true);

        let idsArr = [];
        let chibi = [];

        this.state.chibi.forEach((board) => {
            board.forEach((e) => {
                if(e.check) {
                    chibi.push(e);
                    idsArr.push(e['id_arr']);
                }
            });
        });

        if(chibi.length !== 3) {
            this.props.setSpinner(null);
            this.displayError("Нужно выбрать троих чиби, что бы улучшить");
            return;
        }

        if(chibi[0]['id_chibi'] !== chibi[1]['id_chibi'] ||
            chibi[0]['id_chibi'] !== chibi[2]['id_chibi'] ||
            chibi[1]['id_chibi'] !== chibi[2]['id_chibi']) {
            this.props.setSpinner(null);
            this.displayError("Нужно выбрать троих одинаковых чиби, что бы улучшить");
            return;
        }

        if(chibi[0]['improved'] || chibi[1]['improved'] || chibi[2]['improved']) {
            this.props.setSpinner(null);
            this.displayError("Нужно выбрать троих одинаковых чиби, у вас есть в выбраннах улучшенный чиби");
            return;
        }

        let formData = new FormData();
        formData.append('ids', idsArr);

        axios.post("/api/users.toImproveChibi", formData).then(res => {
            this.props.setSpinner(null);

            if(res.data.error) {
                this.displayError(res.data.error.message);
                return;
            }

            this.props.userUpdate({
                chibi: res.data.response.chibi
            });

            this.loadChibi();
        }).catch(error => {
            console.log(error);
        });
    }

    clickQQ() {
        this.props.setPopout(
            <Modal style={{width: 400, height: 450}}
                   title="Ошибка"
                   onCloseModal={() => this.props.setPopout(null)}
            >
                <Scrollbars renderThumbVertical={() => <div className="thumb_v"/>}>
                    <div style={{height: 450}}>
                        Описание для чего нужны чиби
                    </div>
                </Scrollbars>
            </Modal>
        );
    }

    render() {
        let price = this.getPrice();

        return(
            <div>
                {this.state.showModal? (
                    <Modal
                        title="Коллекция чиби"
                        onCloseModal={() => this.setState({showModal: false})}
                        style={{width: 700, height: 488}}
                    >
                        <div className="chibiModal">
                            <div style={{height: 440}}>
                                <Scrollbars renderThumbVertical={() => <div className="thumb_v"/>}>
                                    {this.state.chibi.map((e, i) => {
                                        return(
                                            <div key={i} className="board">
                                                {e.map((chib, ii) => {
                                                    if(chib.title) {
                                                        return (
                                                            <div key={ii}
                                                                 className="itemChibi"
                                                                 onClick={this.clichChibi.bind(this, i, ii)}>
                                                                <img src={"/images/chibi/" + chib['image'] + ".png"} alt="" />

                                                                <div className="itemTitle" title={chib.title}>
                                                                    {chib.title}
                                                                </div>

                                                                {chib['improved']? (
                                                                    <React.Fragment>
                                                                        <div className="improved left"/>
                                                                        <div className="improved right"/>
                                                                    </React.Fragment>
                                                                ) : null}

                                                                {chib.check? (
                                                                    <div className="checkBox">
                                                                        <Icon
                                                                            name="check"
                                                                            font="FontAwesome"
                                                                            style={{fontSize: 25, color: "#fff"}}
                                                                        />
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        );
                                                    }

                                                    return(
                                                        <div key={ii} className="itemChibi">
                                                            <img src={"/images/chibi/0.png"} alt="" />
                                                            <div className="itemTitle">
                                                                Пустой
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        );
                                    })}
                                </Scrollbars>
                            </div>

                            <div className="buttonsWrap">
                                <div className="button" onClick={this.toSell.bind(this)}>
                                    Продать {price? "за " + price : ""}
                                </div>
                                <div className="button" onClick={this.toImprove.bind(this)}>
                                    Улучшить
                                </div>
                                <div className="button" onClick={this.clickQQ.bind(this)}>
                                    ?
                                </div>
                            </div>
                        </div>
                    </Modal>
                ) : null}

                <div className="chibiWrap" onClick={() => this.setState({showModal: true})}>
                    <div className="chibiIcon">
                        <img src="/images/ui/main/chibi_icon.png" alt="" />
                    </div>
                    <div className="chibiText">
                        ЧИБИ
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        sys: state.sys
    }
}

function mapDispatchToProps(dispatch) {
    return {
        userUpdate: function (name) {
            dispatch(userActions.userUpdate(name));
        },
        setPopout: function (name) {
            dispatch(sysActions.setPopout(name))
        },
        setSpinner: function (name) {
            dispatch(sysActions.setSpinner(name))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Chibi);