import React, {Component} from 'react';
import {connect} from 'react-redux';

import Cropper from 'react-cropper';

import axios from "../../utils/axios";

import 'cropperjs/dist/cropper.css';

import './AddAnswer.scss';


class AddAnswer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            src: "/images/main.jpg",
            allAnswers: [],
            activeItem: {},
            idImg: 1,
            answer: "",
            search: "",
            searchArr: []
        };

        this.cropper = React.createRef();
    }

    componentDidMount() {
        axios.get("/api/admin.getAnswers", {
            params: {
                id_level: this.props.match.params['idLevel']
            }
        }).then(res => {
            if(res.data.error) {
                return;
            }

            this.setState({
                allAnswers: res.data.response.answers
            });
        }).catch(error => {
            console.log(error);
        });
    }

    onChange(e) {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            this.setState({
                src: reader.result
            });
        };
        reader.readAsDataURL(files[0]);
    }

    _crop(){
        // image in dataUrl
        // console.log(this.cropper.current.getCroppedCanvas().toDataURL());
    }

    save() {
        let formData = new FormData();
        this.cropper.current.getCroppedCanvas({
            width: 500,
            height: 460
        }).toBlob(((blob) => {
            formData.append('img', blob);
            formData.append('id_img', this.state.idImg);
            formData.append('id_level', this.props.match.params['idLevel']);
            formData.append('id_map', this.props.match.params['idMap']);
            formData.append('answer', this.state.answer);
            formData.append('id_answer', this.state.activeItem.id);

            axios.post("/api/admin.saveAnswer", formData).then(res => {
                let all = [...this.state.allAnswers];
                let arr = all.map((e) => {
                    if(+e.id === +this.state.activeItem.id) {
                        e['answer'] = "eee";
                    }

                    return e;
                });
                this.setState({
                    activeItem: {},
                    allAnswers: arr
                });
            }).catch(error => {
                console.log(error);
            });
        }));
    }

    onClickItem(item, idImg) {
        this.setState({
            activeItem: item,
            idImg: +idImg + 1
        });
    }

    changeAnswer(e) {
        this.setState({
            answer: e.target.value
        });
    }

    clickSearch(e) {
        let formData = new FormData();
        formData.append('search', this.state.search);

        axios.post("/api/admin.searchAnime", formData).then(res => {
            this.setState({
                searchArr: res.data.response['anime']
            });
        }).catch(error => {
            console.log(error);
        });
    }

    changeSearch(e) {
        this.setState({
            search: e.target.value
        });
    }

    render() {
        if(!this.state.activeItem.id) {
            return (
                <div className="main">
                    <div className="addAnswerWrapper">
                        <div className="leftBlock">
                            {this.state.allAnswers.map((e, i) => {
                                return(
                                    <div key={e.id} className="itemWrap" onClick={this.onClickItem.bind(this, e, i)}>
                                        {e.id}
                                        {e.answer? " +" : " -"}
                                    </div>
                                );
                            })}
                        </div>
                        <div className="rightBlock">
                            <input type="text" className="inputSearch" value={this.state.search} onChange={this.changeSearch.bind(this)}/>

                            <div className="buttonSearch" onClick={this.clickSearch.bind(this)}>
                                Проверить
                            </div>

                            {this.state.searchArr.map((e) => {
                                let img = "/images/answers/map" +
                                    this.props.match.params['idMap'] + "/" + e['id_level'] + "/" + e['img'] + ".jpg";

                                return(
                                    <div key={e.id} className="itemWrap">
                                        <div className="img">
                                            <img src={img} alt="" />
                                        </div>
                                        {e.answer} :: уровень {e['id_level']}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div className="main">
                <div onClick={() => this.setState({activeItem: {}})}>
                    Назад
                </div>
                <Cropper
                    ref={this.cropper}
                    src={this.state.src}
                    style={{height: 500, width: '100%'}}
                    // Cropper.js options
                    aspectRatio={1.09}
                    guides={true}
                    crop={this._crop.bind(this)} />

                <input type="file" onChange={this.onChange.bind(this)} />
                <input type="text" className="addLevelsInMap" onChange={this.changeAnswer.bind(this)}/>
                <div className="bottomAddLevelsInMap" onClick={this.save.bind(this)}>
                    Добавить
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        // user: state.user,
        // game: state.game
    }
}

function mapDispatchToProps(dispatch) {
    return {
        // userUpdate: function (name) {
        //     dispatch(userActions.userUpdate(name))
        // }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddAnswer);