import React, {Component} from 'react';
import {connect} from 'react-redux';

import Modal from '../Modal/Modal';

import VK from '../../helpers/VK';

import * as sysActions from "../../store/sys/actions";
import * as userActions from "../../store/user/actions";

import './Gold.scss';

class Gold extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };

        this.item_id = 0;

        this.price = {
            "1": 600,
            "2": 3000,
            "3": 6000,
            "4": 13800
        };
    }

    componentDidMount() {
        // TODO на серверк order.php сделать нормальным
        VK.addCallback('onOrderSuccess', (order_id) => {
            let gold = +this.props.user.gold;

            if(this.price[this.item_id]) {
                gold = +this.props.user.gold + this.price[this.item_id];
            }

            this.props.userUpdate({
                gold: gold
            });

            this.item_id = 0;
        });
        VK.addCallback('onOrderFail', () => {
            this.item_id = 0;
        });
        VK.addCallback('onOrderCancel', () => {
            this.item_id = 0;
        });
    }

    clickBuy(id) {
        this.item_id = id;

        let params = {
            type: 'item',
            item: id
        };
        VK.callMethod('showOrderBox', params);
    }

    render() {
        return(
            <div>
                {this.props.sys.modals.gold? (
                    <Modal
                        title="Покупка монет"
                        onCloseModal={() => this.props.setActiveModal({gold: false})}
                        style={{width: 600, height: 350}}
                    >
                        <div className="buy_gold_wrap">
                            <div className="one" onClick={this.clickBuy.bind(this, 4)}>
                                <div className="gold">
                                    13800
                                </div>
                                <div className="price">
                                    114 <br />
                                    голосов
                                </div>
                            </div>

                            <div className="other item_3" onClick={this.clickBuy.bind(this, 3)}>
                                <div className="gold">6000</div>
                                <div className="price">48 голосов</div>
                            </div>
                            <div className="other item_2" onClick={this.clickBuy.bind(this, 2)}>
                                <div className="gold">3000</div>
                                <div className="price">26 голосов</div>
                            </div>
                            <div className="other item_1" onClick={this.clickBuy.bind(this, 1)}>
                                <div className="gold">600</div>
                                <div className="price">6 голосов</div>
                            </div>
                        </div>
                    </Modal>
                ) : null}

                <div className="wrap_gold">
                    <div className="gold">
                        {this.props.user.gold}
                    </div>
                    <div onClick={() => this.props.setActiveModal({gold: true})} className="plus"/>
                </div>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        user: state.user,
        sys: state.sys
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setPopout: function (name) {
            dispatch(sysActions.setPopout(name))
        },
        userUpdate: function (name) {
            dispatch(userActions.userUpdate(name))
        },
        setActiveModal: function (name) {
            dispatch(sysActions.setActiveModal(name))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Gold);